/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var mainMenu = function() {

          var $menuContainer = $('#js-menu-container');
          var $menuBtn = $('.js-menu-btn');

          $menuContainer.attr('aria-expanded', 'false');

          var closeMenu = function() {
            $menuContainer.removeClass('is-toggled').attr('aria-expanded', 'false');
            $('body').removeClass('st-menu-open');
            $menuBtn.attr('aria-expanded', 'false').removeClass('is-active');
          };

          /* Toggle menu */
          $menuBtn.on('click', function(e) {

            e.preventDefault();
            e.stopImmediatePropagation();

            $menuContainer.stop().fadeToggle(250);

            if ($menuContainer.is('.is-toggled')) {
              closeMenu();
            } else {
              $menuContainer.addClass('is-toggled').attr('aria-expanded', 'true');
              $('body').addClass('st-menu-open');
              $menuBtn.attr('aria-expanded', 'true').addClass('is-active');
            }

          });

          /* Close menu when a click is made elsewhere on the page */
          $(document).on('click', function(e) {

            if ($menuContainer.is('.is-toggled')) {
              menuArea = $(e.target).closest($menuContainer).length;
              menuBtnArea = $(e.target).closest($menuBtn).length;
              if(!menuArea && !menuBtnArea) {
                $menuContainer.stop().fadeOut(250);
                closeMenu();
              }
            }

          });

        };

        mainMenu();

        var anchorLink = function() {

          $('.js-anchor-link, #js-site-header .menu-item a').on('click', function(e) {

            e.preventDefault();
            var section = $(this).attr('href');
            var headerHeight = $('#js-site-header').outerHeight();
            var scrollDistance = $(section).offset().top - headerHeight;
            // var scrollDistance = $(section).offset().top;

            /*
            if($menuContainer.is('.is-toggled') && $menuBtn.is(':visible')) {
              closeMenu();
              $menuContainer.fadeOut(250);
            }
            */

            $('html, body').stop().animate({
              scrollTop: scrollDistance
            }, 800);

          });

        };

        anchorLink();

        var headerHeight = $('#js-site-header').outerHeight();

        $(window).on('resize', function() {
          window.requestAnimationFrame(function() {
            var headerHeight = $('#js-site-header').outerHeight();
          });
        });

        var headerSize = function() {
          var scrollTop = $(window).scrollTop();
          if(scrollTop > headerHeight) {
            if(!$('#js-site-header').hasClass('st-fixed')) {
              $('#js-site-header').addClass('st-fixed');
            }
          } else {
            if($('#js-site-header').is('.st-fixed')) {
              $('#js-site-header').removeClass('st-fixed');
            }
          }
        };

        headerSize();

        var parallaxItem = $('#js-page-header-bg');

        var parallax = function() {
          var scrollPos = $(window).scrollTop();
          var itemOffset = scrollPos / 2;
          parallaxItem.css({
            '-webkit-transform': 'translate3d(0, ' + itemOffset + 'px, 0)',
            '-ms-transform': 'translate3d(0, ' + itemOffset + 'px, 0)',
            'transform': 'translate3d(0, ' + itemOffset + 'px, 0)',
            'visibility': 'visible',
            'opacity': 1
          });
        };

        parallax();

        $(window).on('scroll', function() {
          window.requestAnimationFrame(headerSize);
          window.requestAnimationFrame(parallax);
        });

        $(window).on('resize', function() {
          window.requestAnimationFrame(headerSize);
        });

        $('#js-media-slider').slick({
          infinite: true,
          prevArrow: $('#js-media-slider-btn-left'),
          nextArrow: $('#js-media-slider-btn-right'),
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          rows: 0,
          responsive: [
            {
              breakpoint: 960,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 720,
              settings: "unslick"
            }
          ]
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
